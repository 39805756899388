.product-configuration-product {
  margin: 0 0 0.75rem 0;
}

.product-configuration-product h5 {
  float: left;
}

.product-configuration-product .trash-icon {
  cursor: pointer;
  float: right;
  margin: 5px;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
