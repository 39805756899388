.place-images {
  margin-bottom: 2rem;
}

.place-image {
  margin-bottom: 0.5rem;
}

.place-image-container {
  align-self: flex-start;
}
