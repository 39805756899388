.tab-selector {
  align-items: center;
  background-color: #d3d3d3;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 132px;
  justify-content: flex-end;
  margin-right: 6px;
  transition: background-color 0.15s ease-in-out;
  width: 132px;
}

.wide-tab {
  width: 162px;
}

div .tab-selected {
  background-color: #f0f0f0;
}

.tab-text {
  font-size: 1rem;
  font-weight: 500;
  margin: 13px 0;
  text-transform: uppercase;
}

.tab-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.tab-selector-add {
  align-items: center;
  background-color: var(--theme-primary-color);
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 132px;
  justify-content: flex-end;
  margin-right: 6px;
  padding: 0;
  transition: background-color 0.15s ease-in-out;
  width: 125px;
}

.tab-text-add {
  color: white;
}

.add-button {
  background-color: var(--theme-primary-color);
  border-radius: 4px;
  color: white;
  font-size: 1.5rem;
  height: 3.5rem;
  padding: 0.3rem;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.15s ease-in-out;
  width: 8rem;
}

.tab-selector-add:hover {
  background-color: #3d6939;
  color: white;
  text-decoration: none;
}

.tab-icon {
  height: 50px;
  width: 50px;
}

@media only screen and (max-width: 768px) {
  .tab-icon {
    height: 30px;
    width: 30px;
  }

  .tab-selector {
    height: 100px;
    width: 115px;
  }

  .tab-selector-add {
    height: 100px;
    width: 90px;
  }

  .add-button {
    font-size: 1.2rem;
  }
}

