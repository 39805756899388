.header-text {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .header-text {
    font-size: 1.2rem;
  }
}
