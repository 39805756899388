.date-picker input:disabled {
  background-color: var(--theme-grey);
}

.react-datepicker-wrapper input {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  width: 100%;
}
 
.react-datepicker-wrapper {
  width: 100%;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
