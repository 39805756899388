.add-button:hover {
  background-color: var(--theme-primary-hover-color);
  color: white;
  text-decoration: none;
}

.button-margin-top {
  margin-top: 10px;
}

div .search-type-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 0;
}

option .select-option {
  font-size: 22px;
}

.row-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

select .form-control {
  font-size: 22px;
}

.select-option {
  text-transform: capitalize;
}

.select-input {
  border: 0;
  border-radius: 0;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

.text-right-margin {
  margin-right: 16px;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
