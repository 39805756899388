.sidebar-link {
  align-items: center;
  background-color: #00000000;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: start;
  transition: background-color 0.15s ease-in-out;
  width: 100%;
}

.sidebar-link:hover {
  background-color: #00000029 ;
  text-decoration: none;
}

.sidebar-link-text {
  color: white;
  font-size: 28px;
  margin-bottom: 0;
  margin-left: 32px;
}

@media (max-width: 576px) {
  a.sidebar-link {
    height: 56px;
  }
}
