.dashboard-image {
  height: 147px;
}

.dashboard-image-container {
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 20px solid var(--theme-primary-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 318px;
  justify-content: flex-end;
  margin: 4px;
  transition: background-color 0.15s ease-in-out;
  user-select: none;
  margin-bottom: 40px;
  margin-left: 10px;
  margin-right: 10px;
  width: 338px;
}

.dashboard-image-container:hover {
  background-color: lightgray;
}

.dashboard-image-container:active {
  background-color: rgb(189, 189, 189);
}

.dashboard-text {
  color: var(--theme-primary-color);
  font-size: 28px;
  font-weight: 500;
  margin: 28px 0 34px 0;
}

.dashboard-link:hover {
  text-decoration: none;
}
