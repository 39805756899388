.products-table .table {
  margin-bottom: 0;
}

.products-table .table td {
  border-top: 0;
}

.products-table .table th {
  border: none;
  border-bottom: 1px solid black;
}
