.product-configuration-item .button-link {
  margin-right: 1rem;
}

.product-configuration-item .buttons {
  margin-top: 1.5rem;
}

.product-configuration-item .col:not(:first-child),
.product-configuration-item .col-1:not(:first-child),
.product-configuration-item .col-2:not(:first-child),
.product-configuration-item .col-3:not(:first-child) {
  padding-left: 8px;
}

.product-configuration-item .col:not(:last-child),
.product-configuration-item .col-1:not(:last-child),
.product-configuration-item .col-2:not(:last-child),
.product-configuration-item .col-3:not(:last-child) {
  padding-right: 8px;
}

.product-configuration-item .content-box {
  background-color: var(--theme-grey-light);
  margin-bottom: 1rem;
  padding: 1.5rem;
}

.product-configuration-item .field-wrapper {
  max-width: 20rem;
}

.product-configuration-item h3 {
  margin-top: 0;
}

.product-configuration-item .product-configuration-item-checkbox {
  margin-top: 2.75rem;
}

.product-configuration-item .search-field {
  max-width: 25rem;
}

.product-configuration-item .structure-header {
  margin-top: 1rem;
}

.product-configuration-item .trash-icon {
  cursor: pointer;
  margin-top: 0.625rem;
}
