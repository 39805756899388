.offer-product .action-buttons {
  margin-top: 1.5rem;
}

.offer-product .button-one-line {
  white-space: nowrap;
}

.offer-product .header-text {
  float: left;
}

.offer-product .price-cell .form-group {
  margin-bottom: 0;
}

.offer-product .version-summary .pdf-checkbox {
  cursor: pointer;
  margin-left: 0.5rem;
}

.offer-product .version-summary th {
  border-top: 0;
}
