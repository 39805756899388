button.primary-button {
  background-color: var(--theme-primary-color);
  border: none;
  color: var(--theme-background-color);
  font-size: 1.2rem;
  font-weight: 500;
}

button.primary-button.button-left {
  float: left;
  margin-left: 0;
  margin-right: 1rem;
}

button.primary-button.button-right {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

button.primary-button:hover {
  background-color: var(--theme-secondary-color);
  color: var(--theme-background-color);
}

button.primary-button:active {
  background-color: var(--theme-primary-color);
}

button.primary-button:focus {
  background-color: var(--theme-primary-color);
  border-color: var(--theme-secondary-color);
  box-shadow: 0 0 0 0.2rem var(--theme-secondary-color);
}

button.primary-button:hover {
  background-color: var(--theme-secondary-color);
}

.primary-dropdown-menu {
  background-color: var(--theme-primary-color);
}

.primary-dropdown-menu button {
  color: white;
  font-size: 1.5rem;
  text-align: right;
}

.dropdown-toggle::after {
  margin-left: 1em;
}

.show > .btn-secondary.dropdown-toggle:focus{
  box-shadow: none;
}

@media only screen and (max-width: 768px) {
  .primary-dropdown-menu button {
    font-size: 1.2rem;
  }
}
