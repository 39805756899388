.images-with-preview-input .image-input {
  display: none;
}

.images-with-preview-input .image-preview {
  margin: 0 1rem 1rem 0;
  position: relative;
}

.images-with-preview-input .add-image-button,
.images-with-preview-input .image-preview {
  background-color: var(--theme-grey-light);
  border: 1px solid var(--theme-grey-dark);
  border-radius: 0.25rem;
  box-sizing: border-box;
  float: left;
  height: 4.5rem;
  width: 6.5rem;
}

.images-with-preview-input .image-preview-actions {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.images-with-preview-input .image-preview-actions img {
  cursor: pointer;
  width: 1rem;
}

.images-with-preview-input .image-preview-actions img:first-child {
  margin-right: 1rem;
}

.images-with-preview-input .image-preview-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
