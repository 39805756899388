.black-text-subheader {
    font-size: 1.5rem !important;
    margin-top: 0 !important;
    padding-top: 0;
}

.customer-grey-header {
    margin-left: 20px;
    margin-right: auto;
}

.grey-text {
    color: var(--theme-grey-dark);
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 0;
}

.project-header {
    margin-bottom: 30px;
}
