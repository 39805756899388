.button-margin-top {
  margin-top: 10px;
}

.flex-form {
  display: flex;
}

.grey-text {
  color: var(--theme-grey-dark);
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
}

.no-padding {
  padding: 0;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
