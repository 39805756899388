.centered-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 132px);
  padding-top: 120px;
  top: 132px;
  width: 100%;
}

.dashboard-header {
  font-size: 36px;
  font-weight: 500;
  margin-top: 60px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .centered-container {
    padding-top: 60px;
  }
}
