.products-table .edit-icon {
    cursor: pointer;
    float: left;
    height: 1.25rem;
}

.products-table .delete-icon {
    float: right;
    height: 1.25rem;
    margin-left: 10px;
    margin-top: 0;
}

.products-table .table {
    margin-bottom: 0;
}

.products-table .table td {
    border-top: 0;
}

.products-table .table th {
    border: none;
    border-bottom: 1px solid black;
}


