.page-container {
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.page-container.full-width-page-container {
  left: 0;
  padding: 0 15px 3rem 15px;
  position: absolute;
  width: 100%;
}
