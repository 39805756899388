.align-right {
  text-align: right;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
