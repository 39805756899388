.filter-row-mittakortti {
    margin-top: 20px;
}

.line-elements {
    display: flex;
    float: left;
}

.placeholder-select {
    color: lightgray;
}
