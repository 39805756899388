.buttons-gap {
    margin-right: 20px;
}

.button-margin-top {
    margin-top: 10px;
}

.title-spacing {
    margin-bottom: 20px;
}
