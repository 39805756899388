:root {
  --theme-background-color: #ffffff;
  --theme-grey: #d3d3d3;
  --theme-grey-dark: grey;
  --theme-grey-light: #f0f0f0;
  --theme-layover-color: #00000028;
  --theme-light-text-color: #95989a;
  --theme-primary-color: #4d8549;
  --theme-primary-hover-color: #3d6939;
  --theme-secondary-color: #9fd03e;
  --theme-secondary-color-with-transparency: #9fd03e44;
  --theme-warning-color: #e01a76;

  --theme-font-size-h1: 2.25rem;
  --theme-font-size-h2: 2rem;
  --theme-font-size-h3: 1.5rem;
  --theme-font-size-h4: 1.3rem;
  --theme-font-size-h5: 1.2rem;
  --theme-font-size-p: 1rem;
}

a {
  color: var(--theme-primary-color);
}

a:hover {
  color: var(--theme-secondary-color);
}

body {
  font-family: 'futura-pt', sans-serif;
}

code {
  color: var(--theme-warning-color);
}

.form-control:focus {
  border-color: var(--theme-secondary-color);
  box-shadow: 0 0 0 0.2rem var(--theme-secondary-color-with-transparency);
}

h1 {
  font-size: var(--theme-font-size-h1);
  font-weight: 500;
  margin-top: 2rem;
}

h2 {
  font-size: var(--theme-font-size-h2);
  font-weight: 500;
  margin-top: 1.75rem;
}

h3 {
  font-size: var(--theme-font-size-h3);
  font-weight: 500;
  margin-top: 1.5rem;
}

h4 {
  font-size: var(--theme-font-size-h4);
}

h5 {
  font-size: var(--theme-font-size-h5);
}

.label {
  color: #3c3c3c;
  font-size: 1.2rem;
  font-weight: bold;
}

p {
  font-size: var(--theme-font-size-p);
}

.theme-text-color {
  color: var(--theme-primary-color);
}

@media only screen and (max-width: 768px) {
  .label {
    font-size: 0.9rem;
  }
}
