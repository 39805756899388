.offer-save-button {
    float: right;
}

.button-one-line {
    white-space: nowrap;
}

.form-group .read-only-text-input {
    margin-bottom: 0.5rem;
}

.compact-header {
    margin-top: 0.1rem;
    margin-bottom: 0.5rem;
}