.sidebar-content {
  background: #4d854a 0 0 no-repeat padding-box;
  box-shadow: 0 0 20px #00000029;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: -382px;
  padding-bottom: 16px;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  width: 382px;
  z-index: 99;
}

div.enter {
  left: 0;
}

.sidebar-image {
  height: 200px;
  margin-bottom: 16px;
  width: 100%;
}

.sidebar-outside {
  background-color: #00000000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  width: 100vw;
  z-index: 98;
}

div.sidebar-dark {
  background-color: #00000029;
}

@media (max-width: 576px) {
  .sidebar-image {
    display: none;
  }

  div.sidebar-content {
    height: calc(100vh - 99px);
    top: 99px;
  }
}
