.react-select-dropdown {
  margin-bottom: 1rem;
}

.calculation-window .button-one-line {
  white-space: nowrap;
}

.calculation-window h2 {
  margin-bottom: 1.2rem;
}

.calculation-window .calculation-sum-row {
  padding-right: 0.75rem;
}

.calculation-window .text-field {
  font-size: 1rem;
}

.calculation-window .col:not(:first-child),
.calculation-window .col-2:not(:first-child),
.calculation-window .col:not(:first-child),
.calculation-window .col-1:not(:first-child) {
  padding-left: 4px;
}

.calculation-window .col:not(:last-child),
.calculation-window .col-2:not(:last-child),
.calculation-window .col:not(:last-child),
.calculation-window .col-1:not(:last-child) {
  padding-right: 4px;
}

.calculation-window .icon-column.col {
  box-sizing: content-box;
  max-width: 1rem;
  padding-left: 0.1rem;
}

.calculation-window .icon-column .collapse-icon {
  margin-left: -0.2rem;
}

.calculation-window .icon-column .collapse-icon,
.calculation-window .icon-column .trash-icon {
  cursor: pointer;
  margin-top: 0.6rem;
}

.calculation-window .installation-row,
.calculation-window .subproduct-row {
  margin-right: -28px;
}

.calculation-window .medium-column {
  box-sizing: content-box;
  max-width: 10rem;
}

.calculation-window .calculation-window-memo {
  width: 50%;
}

.calculation-window .offer-product-version-select-form-group {
  float: left;
}

.calculation-window .set-primary-offer-product-version {
  margin-top: 2.5rem;
}

.calculation-window .small-column {
  box-sizing: content-box;
  max-width: 7.5rem;
}

.calculation-window .subproducts-header h2 {
  float: left;
  margin: 0 1rem 0 0;
}

.calculation-window-header-actions {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}

.calculation-window-header-actions button {
  margin-left: 1rem;
}

.calculation-window-action-buttons-wrapper {
  position: relative;
}

.calculation-window-action-buttons {
  bottom: 0;
  position: absolute;
  right: 0;
}

.calculation-window .item-details-box {
  background-color: #c5c5c5;
  margin: 0 12px 1rem 0;
  padding: 1rem;
}

.calculation-window .item-details-checkbox {
  margin-bottom: 1rem;
  margin-left: 15vw;
}
