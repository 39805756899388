.button-margin-top {
  margin-top: 10px;
}

.flex-form {
  display: flex;
}

.input-size {
  height: 2.7rem;
  width: 100%;
}

.no-padding {
  padding: 0;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
