.align-right {
  text-align: right;
}

.number-with-unit-input-field {
    position: relative;
}

.number-with-unit-input-field p.text-field {
    bottom: 0.425rem;
    margin: 0;
    position: absolute;
    right: 0.7rem;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
