.role-view .add-permission-row {
  margin-bottom: 1rem;
}

.role-view .trash-icon {
  cursor: pointer;
  margin-top: 0.625rem;
}

.role-view .add-button-margin {
  margin-left: 16px;
}

.role-view .form-spacing {
  margin-bottom: 20px;
}
