.add-link-to-product .button-link {
  margin-right: 1rem;
}

.add-link-to-product .buttons {
  margin-top: 1.5rem;
}

.add-link-to-product .content-box {
  background-color: var(--theme-grey-light);
  margin-bottom: 1rem;
  padding: 1.5rem;
}

.add-link-to-product h3 {
  margin-top: 0;
}

.add-link-to-product .search-field {
  max-width: 25rem;
}
