.category-field {
  height: 100%;
  width: 300px;
}

.col-small-padding {
  padding-left: 5px;
  padding-right: 5px;
}

.glass-configurator-fields {
  width: 15rem;
}

.no-padding {
  padding: 0;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .glass-configurator-fields {
    width: 10rem;
  }

  .text-field {
    font-size: 0.9rem;
  }
}
