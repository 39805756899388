.number-with-dropdown-input-field input {
  padding-right: 0;
}

.number-with-dropdown-input-field select {
  border-left: 2px solid var(--theme-grey-light);
  padding-left: 0;
  padding-right: 0;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}
