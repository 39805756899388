.modal-button {
  background-color: var(--theme-primary-color);
  font-size: 22px;
  font-weight: 500;
}

.modal-button:hover {
  background-color: var(--theme-primary-hover-color);
}

.modal-button:active {
  background-color: var(--theme-primary-hover-color) !important;
}

.modal-button:focus {
  background-color: var(--theme-primary-hover-color) !important;
}

div .modal-header {
  border-bottom: none;
}

div .modal-body {
  font-size: 22px;
}

div .modal-footer {
  border-top: none;
}

div .modal-title {
  font-size: 28px;
  font-weight: 500;
}
