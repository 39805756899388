.basic-table {
    background-color: #f0f0f0;
    padding: 1.8rem;
}

.basic-material-table {
    background-color: #f0f0f0;
}

.basic-material-table .primary-button {
    margin-bottom: 1rem;
}

.basic-table thead th {
    border-bottom: 1px solid black;
}

.page-item.active .page-link {
    background-color: grey;
    border-color: grey;
}

.page-link {
    color: grey;
}

.header-add-btn {
    text-align: right;
}

.row-body td {
    color: #3c3c3c;
    font-size: 1.2rem;
    font-weight: 500;
}

.row-body {
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.row-body:hover {
    background-color: grey;
}

.react-bootstrap-table-page-btns-ul {
    float: right;
}

.subtable-no-header th {
    border-bottom: 0 !important;
    padding: 0;
}

.subtable-row td {
    border-top: 1px solid grey;
}

.subtable {
    padding-left: 35%;
}

.space-header p {
    color: grey;
    font-size: 0.9rem;
    margin-bottom: 0;
}

.no-padding-td td {
    padding-bottom: 0;
    padding-top: 0;
}

.no-padding-td {
    border-top: 1px solid grey;
}

.squares-edit {
    margin-left: 1rem;
}

.checkbox-table {
    margin-left: 0;
}

.checkbox-column {
    width: 20px;
}

.fixed-td {
    width: 200px;
}

.row-body td.theme-text-color {
    color: var(--theme-primary-color);
}

.basic-table .dropdown-item {
    padding: 0;
}

.basic-table .dropdown-item a {
    display: block;
    padding: 0.25rem 1.5rem;
    position: relative;
}

.MuiPaper-elevation2 {
    box-shadow: none !important;
    background-color: transparent !important;
}

.MuiPopover-paper {
    background-color: white;
}

.table-body td {
    border: 0;
}

.table-header th {
    border-top: none;
    font-size: 1.2rem;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .basic-table-width {
        min-width: 400px;
    }

    .table-background {
        width: 700px;
    }

    .table-background-offers {
        width: 500px;
    }

    .basic-table {
        padding: 0.5rem;
    }

    .non-fixed-table {
        width: 600px;
    }

    .row-body td {
        font-size: 1rem;
    }
}
