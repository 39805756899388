.product-configuration-new .links-to-products h2 {
  float: left;
  margin-top: 0;
}

.product-configuration-new .links-to-products .add-product-button {
  float: right;
}

.product-configuration-new .links-to-products .trash-icon {
  cursor: pointer;
  float: right;
}
