.add-button-margin {
  margin-left: 16px;
}

.glass-configurator-products {
  height: calc(100vh - 100px);
  left: 0;
  overflow: auto;
  position: absolute;
  width: 100vw;
}

.glass-configurator-products h3 {
  margin-left: 0.5rem;
}

.glass-configurator-products .basic-form-container {
  position: absolute;
}

.glass-configurator-products label {
  display: table-cell;
  height: 3.5rem;
  vertical-align: bottom;
}

.glass-configurator-products .large-column input,
.glass-configurator-products .large-column label {
  min-width: 15rem;
}

.glass-configurator-products .medium-column input,
.glass-configurator-products .medium-column label,
.glass-configurator-products .medium-column select,
.glass-configurator-products .unit-medium-column .input-group {
  min-width: 10rem;
}

.glass-configurator-products .extra-small-column,
.glass-configurator-products .extra-small-column label {
  width: 7rem;
}

.glass-configurator-products .small-column,
.glass-configurator-products .small-column label {
  width: 8.5rem;
}
