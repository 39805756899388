.product-configuration-new .product-configuration-items-title {
  float: left;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.product-configuration-new .product-configurator-items h4 {
  padding-top: 0.5rem;
}

.product-configuration-new .children-container {
  border-left: 2px solid var(--theme-primary-color);
  margin-left: 0.5rem;
  padding-left: 0.5rem;
}

.product-configuration-new .item-actions {
  float: right;
}

.product-configuration-new .item-title {
  float: left;
}
