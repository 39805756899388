.file-input-dropzone {
  border: 0.25rem solid var(--theme-grey);
  border-radius: 1rem;
  border-style: dashed;
  cursor: pointer;
  padding: 2rem;
  text-align: center;
}

.file-input-dropzone:focus {
  outline: none;
}

.file-input-dropzone img {
  margin-bottom: 1rem;
  width: 3rem;
}

.file-input-dropzone h5 {
  color: var(--theme-light-text-color);
  margin: 0;
}
