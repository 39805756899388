.product-configuration-new .content-box {
  background-color: var(--theme-grey-light);
  margin-bottom: 1rem;
  padding: 1.5rem;
}

.edit-modal-bg {
  background-color: var(--theme-grey-light);
}

.edit-configuration-offer-text {
  height: 150px!important;
}
