.add-button-margin {
  margin-left: 16px;
}

.block {
  display: block;
}

.contact-type-label {
  font-size: 1.2rem;
  text-transform: capitalize;
}

.contact-type-label-no-cap {
  font-size: 1.2rem;
}

.contact-button {
  background-color: var(--theme-primary-color);
  font-size: 1.5rem;
  font-weight: 500;
}

.contact-button:hover {
  background-color: var(--theme-primary-hover-color);
}

.contact-button:active {
  background-color: var(--theme-primary-hover-color) !important;
}

.contact-button:focus {
  background-color: var(--theme-primary-hover-color) !important;
}

.content-background {
  background-color: var(--theme-grey-light);
  padding: 16px;
}

div.no-touching-box {
  cursor: auto;
  margin-left: 16px;
}

div.no-touching-box:hover {
  background-color: #e0e0e0;
}

.label-trim {
  margin-left: 8px;
}

.person-box {
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 16px 0 16px 0;
  transition: background-color 0.15s ease-in-out;
  width: 240px;
}

.person-box:hover {
  background-color: #d0d0d0;
  color: black;
  text-decoration: none;
}

.person-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.person-name {
  margin: 0;
}

.contact-type-label {
  font-size: 1.2rem;
}

.search-field div {
  border: none;
}

.search-field span {
  width: 0;
}

.search-field svg {
  margin-right: -7px;
  stroke-width: 0.1px;
  width: 16px;
}

.search-field div div div {
  color: #495057;
}

.search-field div:hover {
  border: none;
  box-shadow: none;
}

.type-checkmark {
  margin-top: 0.6rem;
  transform: scale(1.3);
}

@media only screen and (max-width: 768px) {
  .contact-type-label {
    font-size: 1rem;
  }
  .contact-button {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1.2rem !important;
  }
}
