.search-field div {
  border: none;
}

.search-field span {
  width: 0;
}

.search-field svg {
  margin-right: -7px;
  stroke-width: 0.1px;
  width: 16px;
}

.search-field div div div {
  color: #495057;
}

.search-field div:hover {
  border: none;
  box-shadow: none;
}

.subsection {
  margin-top: 50px;
}

.text-field {
  border: none;
  border-radius: 4px;
  clear: both;
  font-size: 1.2rem;
}

.title-spacing {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .text-field {
    font-size: 0.9rem;
  }
}
