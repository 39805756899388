@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.layout-spinner {
  color: var(--theme-primary-color);
  left: 50%;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}

.layout-spinner img {
  animation: spinner 2s linear infinite;
}

.layout-spinner-layover {
  background-color: var(--theme-layover-color);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}
