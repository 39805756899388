.nav-title {
  color: white;
  font-weight: bold;
}

.nav-title h2 {
  font-size: 22px;
  margin: 0.325rem 0 0.325rem 1rem;
}

.nav-title h2 a {
  color: white;
}

.nav-title h2 a:hover {
  color: var(--theme-secondary-color);
  text-decoration: none;
}

a.navbar-brand {
  text-align: center;
  white-space: normal;
  word-break: break-all;
}

.navbar-color {
  background-color: var(--theme-primary-color);
}

.navbar-size {
  height: 100px;
  margin: 0;
  z-index: 80;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  div.full-container {
    margin: 0;
  }

  ul.navmenu-hamburger-button {
    margin-right: 16px;
  }

  a.account-name-container {
    display: none;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navmenu-hamburger-button {
  align-items: center;
  background-color: #9fd03f;
  cursor: pointer;
  display: flex;
  height: 99px;
  justify-content: center;
  margin-right: 32px;
  transition: background-color 0.15s ease-in-out;
  user-select: none;
  width: 100px;
}

.navmenu-hamburger-button:hover {
  background-color: #89b928;
}

.navmenu-hamburger-text {
  color: white;
  font-size: 41px;
  margin: 0;
}

.account-name {
  color: white;
  font-size: 20px;
  margin-left: 10px;
}

.account-name-container {
  display: flex;
  flex-direction: row;
}

.account-name-container:hover {
  text-decoration: none;
}

.account-logo {
  height: 32px;
  width: 32px;
}

.full-container {
  margin: 0 32px 0 0;
  max-width: 100%;
  padding: 0;
}

.space-reserver{
  height: 100px;
}
