.products-page .add-product-button {
  float: right;
  margin: 0 0 1rem 1rem;
  text-transform: uppercase;
}

.products-page .glass-configurator-button {
  text-transform: uppercase;
}

.products-page .header-text {
  float: left;
}

@media only screen and (max-width: 768px) {
  .products-page .glass-configurator-button {
      font-size: 1.2rem;
  }
}
