.product-files h3 {
  margin: 0.5rem 0;
  text-transform: uppercase;
}

.product-files .product-file-link {
  color: var(--theme-primary-color);
  cursor: pointer;
  float: left;
  margin-bottom: 0.2rem;
}

.product-files .product-files-list {
  margin-bottom: 1rem;
}

.product-files .trash-icon {
  cursor: pointer;
  margin-left: auto;
}
