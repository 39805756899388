.add-button-margin {
  margin-left: 16px;
}

.product-measurement:not(:first-child) .delete-icon {
  margin-top: 12px;
}

.product-attribute:not(:first-child) .delete-icon {
  margin-top: 12px;
}

.product-price:not(:first-child) .delete-icon {
  margin-top: 12px;
}
